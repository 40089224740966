<template>
    
    <div v-if="talkingPoints" class="h-auto w-full flex flex-col">
        <loader :loading="loading" />

        <div class="h-auto p-2">

            <div class="h-auto w-full rounded-lg module p-2 flex flex-col">

                <div class="h-auto py-1">
                    <span class="text-good text-xs font-semibold">Talking points & Firm Docs</span>
                </div>

                <div class="h-6 rounded-lg bg-body my-2">
                   <input type="text" placeholder="Search" v-model="searchInput" class="w-full h-6 rounded bg-body flex flex-row justify-start text-xs items-center px-2">
                </div>

                <div class="flex mt-1 mb-2 items-center">
                    <div class="header-btn-gray ml-3 relative" v-for="(doc, index) in typeDocs"
                         :key="index" :class="{ [doc.class]: selectedExtensions === doc.value }"
                         @click="filterByExtensions(doc.value)" >
                        <i :class="'text-xl mdi mdi-' + doc.name"></i>
                    </div>
                </div>

                <div v-if="el.file && el.file.length > 0" class="h-auto rounded-lg mt-1 flex flex-col"
                     v-for="(el, index) in search(filteredTalkingPoints)" :key="index">

                    <div  class="box h-12 flex items-center p-4">
                        <div class="flex-none rounded h-8 w-8 flex items-center justify-center text-center cursor-pointer"
                             :class="getClassByExtension(el.file)" @click="openFile(el.file, 'file')">
                            <i class="mdi text-2xl" :class="getIconByExtension(el.file)"></i>
                        </div>
                        <div class="flex-1 min-w-0">
                            <p class="text-xs text-font-light font-medium px-4 truncate-2 leading-normal">{{ el.title }}</p>
                        </div>
                        <div class="text-font-gray self-start" style="font-size: 0.50rem;">
                            <p>{{ el.date | moment('MM/DD/YYYY') }}</p>
                        </div>
                    </div>
                </div>

            </div>
            <div v-if="search(filteredTalkingPoints).length === 0" class="h-auto w-full pt-4 flex-center">
                <span class="text-xs text-font-gray">There are no files of the selected extension</span>
            </div>

        </div>

    </div>

</template>

<script>

import {state} from '@/store';

export default {
    data(){
        return{
            typeDocs: this.$typeDocs,
            talkingPoints: [],
            searchInput:'',
            selectedExtensions: [],
            loading: false
        }
    },
    methods:{
        getIconByExtension(file) {
            let result = ''
            result += this.$iconByExtension(file)
            return result
        },
        getClassByExtension(file) {
            let result = ''
            result += this.$colorByExtension(file)
            return result
        },
        openFile(file, type) { this.$openFile(file, type) },
        isValid(link) { return this.$is_url(link) },
        goLink(link) { this.$goLink(link) },
        load(){
            this.loading = true
            this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'list?resource=TalkingPointAndFirmDoc&sorts[date]=DESC&filters[active]=1', {params: state.globalParams}).then(response => {
                this.talkingPoints = response.data
                this.loading = false
            })
        },
        filterByExtensions(extensions) {
            if (extensions === this.selectedExtensions) this.selectedExtensions = []
            else this.selectedExtensions = extensions
        },
        search(data){ return this.$search(data, this.searchInput) }
    },
    computed:{
        filteredTalkingPoints() {
            if (this.selectedExtensions.length === 0) { return this.talkingPoints }
            return this.talkingPoints.filter(({ file }) => {
                const pathProps = file.split('.');
                const extension = pathProps[pathProps.length - 1];
                return this.selectedExtensions.includes(extension);
            });
        },
    },
    mounted(){
        this.load();
    }
}
</script>